let caseTypesOfData = {
    '1': [
        {
            id: '1-1',
            name: '财产案件',
            calculate: {
                //最小取值区间
                //第一个参为 区间 第二个参为倍数 第三个参为倍数类型 per 百分比 acc具体数额
                data: [
                    [10000, 50, 'acc'],
                    [100000, 2.5, 'per'],
                    [200000, 2, 'per'],
                    [500000, 1.5, 'per'],
                    [1000000, 1, 'per'],
                    [2000000, 0.9, 'per'],
                    [5000000, 0.8, 'per'],
                    [10000000, 0.7, 'per'],
                    [20000000, 0.6, 'per'],
                    [20000000, 0.5, 'per', 'max'],
                ],
            },
        },
        {
            id: '1-2',
            name: '离婚案件（不涉及财产分割）',
            calculate: { unitPrice: [50, 300] },
        },
        {
            id: '1-3',
            name: '离婚案件（涉及财产分割）',
            calculate: {
                data: [[200000, 0.5, 'per', 'max']],
                unitPrice: [50, 300],
            },
        },
        {
            id: '1-4',
            name:
                '侵害姓名权、名称权、肖像权、名誉权、荣誉权以及其他人格权的案件（不涉及损害赔偿）',
            calculate: {
                unitPrice: [100, 500],
            },
        },
        {
            id: '1-5',
            name: '侵害姓名权、名称权、肖像权、名誉权、荣誉权以及其他人格权的案件（涉及损害赔偿）',
            calculate: {
                data: [
                    [50000, 0, 'acc'],
                    [100000, 1, 'per'],
                    [100000, 0.5, 'per', 'max'],
                ],
                unitPrice: [100, 500],
            },
        },
        {
            id: '1-6',
            name: '其他非财产案件（如收养案件等）',
            calculate: {
                unitPrice: [50, 100],
            },
        },
        {
            id: '1-7',
            name: '知识产权民事案件（没有争议金额或者价额）',
            calculate: {
                unitPrice: [500, 1000],
            },
        },
        {
            id: '1-8',
            name: '知识产权民事案件（有争议金额或者价额）',
            calculate: {
                data: [
                    [10000, 50, 'acc'],
                    [100000, 2.5, 'per'],
                    [200000, 2, 'per'],
                    [500000, 1.5, 'per'],
                    [1000000, 1, 'per'],
                    [2000000, 0.9, 'per'],
                    [5000000, 0.8, 'per'],
                    [10000000, 0.7, 'per'],
                    [20000000, 0.6, 'per'],
                    [20000000, 0.5, 'per', 'max'],
                ],
            },
        },
        {
            id: '1-9',
            name: '劳动争议案件',
            calculate: {
                unitPrice: [10],
            },
        },
        {
            id: '1-10',
            name: '商标、专利、海事行政案件',
            calculate: {
                unitPrice: [100],
            },
        },
        {
            id: '1-11',
            name: '除商标、专利、海事外的其他行政案件',
            calculate: {
                unitPrice: [50],
            },
        },
        {
            id: '1-12',
            name: '当事人提出案件管辖权异议，异议不成立的案件',
            calculate: {
                unitPrice: [50, 100],
            },
        },
        {
            id: '1-13',
            name: '依照民事诉讼法规定的特别程序审理的案件',
            calculate: {
                unitPrice: [0],
            },
        },
        {
            id: '1-14',
            name: '裁定不予受理、驳回起诉、驳回上诉的案件',
            calculate: {
                unitPrice: [0],
            },
        },
        {
            id: '1-15',
            name: '对不予受理、驳回起诉和管辖权异议裁定不服，提起上诉的案件',
            calculate: {
                unitPrice: [0],
            },
        },
        {
            id: '1-16',
            name: '行政赔偿案件',
            calculate: {
                unitPrice: [0],
            },
        },
        {
            id: '1-17',
            name: '再审案件',
            calculate: {
                unitPrice: [0],
            },
        },
    ],
    2: [
        {
            id: '2-1',
            name: '申请执行（没有执行金额或者价额）',
            calculate: {
                unitPrice: [50, 500],
            },
        },
        {
            id: '2-2',
            name: '申请执行（有执行金额或者价额）',
            calculate: {
                data: [
                    [10000, 50, 'acc'],
                    [500000, 1.5, 'per'],
                    [5000000, 1, 'per'],
                    [10000000, 0.5, 'per'],
                    [10000000, 0.1, 'per', 'max'],
                ],
            },
        },
        {
            id: '2-3',
            name: '申请保全措施',
            calculate: {
                data: [
                    [1000, 30, 'acc'],
                    [100000, 1, 'per'],
                    [100000, 0.1, 'per', 'max'],
                ],
                max: 5000,
            },
        },
        {
            id: '2-4',
            name: '申请支付令',
            calculate: {
                data: [
                    [10000, 50, 'acc'],
                    [100000, 2.5, 'per'],
                    [200000, 2, 'per'],
                    [500000, 1.5, 'per'],
                    [1000000, 1, 'per'],
                    [2000000, 0.9, 'per'],
                    [5000000, 0.8, 'per'],
                    [10000000, 0.7, 'per'],
                    [20000000, 0.6, 'per'],
                    [20000000, 0.5, 'per', 'max'],
                ],
                cutBack: [3, 'mul'],
            },
        },
        {
            id: '2-5',
            name: '申请公示催告的',
            calculate: {
                unitPrice: [100],
            },
        },
        {
            id: '2-6',
            name: '申请撤销仲裁裁决或者认定仲裁协议效力',
            calculate: {
                unitPrice: [400],
            },
        },
        {
            id: '2-7',
            name: '破产案件',
            calculate: {
                data: [
                    [10000, 50, 'acc'],
                    [100000, 2.5, 'per'],
                    [200000, 2, 'per'],
                    [500000, 1.5, 'per'],
                    [1000000, 1, 'per'],
                    [2000000, 0.9, 'per'],
                    [5000000, 0.8, 'per'],
                    [10000000, 0.7, 'per'],
                    [20000000, 0.6, 'per'],
                    [20000000, 0.5, 'per', 'max'],
                ],
                cutBack: [2, 'mul'],
                max: 300000,
            },
        },
        {
            id: '2-8',
            name: '申请设立海事赔偿责任限制基金',
            calculate: {
                unitPrice: [1000, 10000],
            },
        },
        {
            id: '2-9',
            name: '申请海事强制令',
            calculate: {
                unitPrice: [1000, 5000],
            },
        },
        {
            id: '2-10',
            name: '申请船舶优先权催告',
            calculate: {
                unitPrice: [1000, 5000],
            },
        },
        {
            id: '2-11',
            name: '申请海事债权登记',
            calculate: {
                unitPrice: [1000],
            },
        },
        {
            id: '2-12',
            name: '申请共同海损理算',
            calculate: {
                unitPrice: [1000],
            },
        },
    ],
}

export { caseTypesOfData }
