<template>
    <div>
        <div class="font">计算结果： </div>
        <div class="result-main">
            <span v-for="(item, index) in result" :key="index">
                <em v-if="index !== 0">--</em>
                <b>{{ item }}</b>
                <strong>元</strong>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'calculator-result',
        props: ['result'],
    }
</script>

<style scoped lang="less">
    .font {
        font-size: 20px;
        color: #333;
        margin-top: 0.3rem;
        padding-left: 0.32rem;
    }
    .result-main {
        padding: 0.1rem 0 0 1.75rem;
        font-size: 24px;
        b {
            margin: 0 0.05rem;
        }
        strong {
            font-size: 16px;
        }
    }
</style>
