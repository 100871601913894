<template>
    <a-form-model-item :label="font">
        <a-select v-model="form[label]" class="select" dropdownClassName="calculator-select">
            <a-select-option v-for="item in data" :key="item.id" :value="item.id">
                {{ item.name }}
            </a-select-option>
        </a-select>
    </a-form-model-item>
</template>

<script>
    export default {
        name: 'calculator-select',
        props: ['form', 'label', 'data', 'font'],
    }
</script>

<style scoped lang="less">
    @import 'calculator.less';
    .select {
        height: 50px;
        /deep/.ant-select-selection {
            height: 50px;
            border-radius: 8px;
        }
        /deep/.ant-select-selection__rendered,
        /deep/.ant-select-selection-selected-value {
            padding: 0 10px;
            height: 100%;
            line-height: 50px;
            font-size: 16px;
        }
    }
</style>

<style lang="less">
    .calculator-select {
        .ant-select-dropdown-menu {
            max-height: 3.2rem;
            &::-webkit-scrollbar {
                width: 2px;
                background-color: #fff;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #358cf3;
            }
            .ant-select-dropdown-menu-item {
                line-height: 0.4rem !important;
            }
        }
    }
</style>
