<template>
    <a-form-model-item :label="font">
        <a-input v-model="form[label]" @change="onchange" :suffix="suffix" />
    </a-form-model-item>
</template>

<script>
    export default {
        name: 'calculator-inputNumber',
        props: ['form', 'label', 'suffix', 'font'],
        methods: {
            onchange(value) {
                this.form[this.label] = this.form[this.label]
                    .replace(/[^\d]/g, '')
                    .substr(0, 20)
                    .toString()
            },
        },
    }
</script>

<style scoped lang="less">
    @import 'calculator.less';
    .ant-input-affix-wrapper {
        /deep/.ant-input {
            height: 50px;
            border-radius: 8px;
            border: 1px solid #d9d9d9 !important;
            padding: 0 28px;
            color: rgba(0, 0, 0, 0.85) !important;
            font-size: 16px !important;
        }
    }
</style>
