<template>
    <div>
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <CalculatorRadio
                :form="form"
                label="moneyTypesOf"
                @radioChange="radioChange"
                font="费用类型"
            ></CalculatorRadio>
            <CalculatorSelect
                :form="form"
                label="caseTypesOf"
                :data="caseTypesOfValue"
                font="案件类型"
            ></CalculatorSelect>

            <CalculatorInputNumber
                :form="form"
                label="money"
                suffix="元"
                font="标的额"
            ></CalculatorInputNumber>
        </a-form-model>
        <ButtonView @start="start" @reset="reset"></ButtonView>
        <Info></Info>
        <CalculatorResult :result="result"></CalculatorResult>
    </div>
</template>

<script>
    import CalculatorInputNumber from '@/components/PC/calculator/calculator-inputNumber.vue'
    import CalculatorRadio from '@/components/PC/calculator/calculator-radio.vue'
    import CalculatorSelect from '@/components/PC/calculator/calculator-select.vue'
    import ButtonView from '@/components/PC/calculator/button-view.vue'
    import Info from '@/components/PC/calculator/info.vue'
    import CalculatorResult from '@/components/PC/calculator/calculator-result.vue'
    import { caseTypesOfData } from './legalFare'
    import { intervalCalculation } from './intervalCalculation'

    export default {
        name: 'legalFare',
        components: {
            CalculatorInputNumber,
            CalculatorRadio,
            CalculatorSelect,
            ButtonView,
            Info,
            CalculatorResult,
        },
        mounted() {
            this.$store.commit('setSpecific', '诉讼费计算器')
        },
        computed: {
            caseTypesOfValue() {
                return this.caseTypesOfData[this.form.moneyTypesOf]
            },
        },
        data() {
            return {
                labelCol: { span: 5 },
                wrapperCol: { span: 17 },
                form: {
                    moneyTypesOf: '1',
                    caseTypesOf: '1-1',
                    money: '',
                },
                caseTypesOfData,
                result: [],
            }
        },
        methods: {
            start() {
                this.form.money = Number(this.form.money) || 0

                let data = caseTypesOfData[this.form.moneyTypesOf].filter((item) => {
                    return item.id == this.form.caseTypesOf
                })

                this.result = intervalCalculation(data[0].calculate, this.form.money)

                this.result.splice(0, 0)
            },
            reset() {
                this.result = []
                this.form = {
                    moneyTypesOf: '1',
                    caseTypesOf: '1-1',
                    money: '',
                }
            },
            radioChange(target) {
                this.form.caseTypesOf = this.caseTypesOfData[target.value][0].id
                this.result = []
                this.form.money = ''
            },
        },
    }
</script>

<style lang="less" type="text/scss" scoped></style>
