function intervalCalculation({ data = [], maxData, max, cutBack, unitPrice = [] }, money) {
    let result = []
    money = Number(money)

    result.push(calculateFn(data, money, max, cutBack, unitPrice[0] || 0))

    if (maxData || unitPrice[1]) {
        let result2 = calculateFn(
            maxData || data,
            money,
            max,
            cutBack,
            unitPrice[1] || unitPrice[0] || 0
        )

        if (result[0] != result2) {
            result.push(result2)
        }
    }

    return result
}

function calculateFn(data, money, max, cutBack, unitPrice) {
    let arr = data.map((element, index) => {
        if (index && !element[3]) {
            if (money > element[0]) {
                return calculation(element[0], data[index - 1][0], element[1], element[2])
            } else if (money > data[index - 1][0]) {
                return calculation(money, data[index - 1][0], element[1], element[2])
            }
            return 0
        } else if (element[3]) {
            return money > element[0] ? calculation(money, element[0], element[1], element[2]) : 0
        } else {
            if (money > element[0]) {
                return calculation(element[0], 0, element[1], element[2])
            } else {
                return calculation(money, 0, element[1], element[2])
            }
        }
    })

    let sum = unitPrice
    arr.forEach((element) => {
        sum = sum + element
    })

    if (cutBack) {
        if (cutBack[1] == 'per') {
            sum = (sum * (cutBack[0] / 100)).toFixed(2)
        } else if (cutBack[1] == 'mul') {
            sum = (sum / cutBack[0]).toFixed(2)
        } else if (cutBack[1] == 'acc') {
            sum = sum - cutBack[0]
        }
    }

    if (max) {
        sum = sum > max ? max : sum
    }

    return sum
}

function calculation(befor, after, coefficient, type) {
    if (type == 'acc') {
        return coefficient
    } else {
        return ((befor - after) * coefficient) / 100
    }
}

export { intervalCalculation }
